import React from 'react';
import useUserMaintenance from '../../../context';
import FeaturesGroup from './FeaturesGroup';
import translator from '../../../../../../services/translator';
import { featureConfig } from './config';
import { YES, CREATE_USER } from '../../../constants';
import './index.scss';

const { translate: t } = translator;

const Features = () => {
  const {
    state: { isEditable, userDetails },
    storeValues: { pageKey },
  } = useUserMaintenance();

  const { isInternal, entitlements = {} } = userDetails;
  const { core, accounts, funds, trading, coreReports, otherReports } = featureConfig;

  const disableSelectAll = isInternal || entitlements.all === YES;
  const readOnly = isEditable && !disableSelectAll && pageKey !== CREATE_USER;

  return (
    <div className='userdetails-feature-container'>
      {readOnly && (
        <div className='nonEditable__banner'>
          <span className='nonEditable__bannerIcon' />
          <div className='nonEditable__bannerContent'>{t('tkMosaicAllPermitRole')}</div>
        </div>
      )}
      <section>
        <div data-testid='feature-entitlements' className='features-header'>
          {t('tkFeatureEntitlements')}
        </div>
        <div className='features-container'>
          <div className='features-left'>
            <FeaturesGroup data={core} title='tkCore' selectedKey='core' testId='core-subtitle' />
            <FeaturesGroup data={accounts} title='tkAccounts' selectedKey='accounts' testId='account-subtitle' />
          </div>
          <div className='features-right'>
            <FeaturesGroup data={funds} title='tkFunds' selectedKey='funds' testId='fund-subtitle' />
            <FeaturesGroup data={trading} title='tkTrading' selectedKey='trading' testId='trading-subtitle' />
          </div>
        </div>
      </section>
      <section>
        <div data-testid='report-entitlements' className='reports-header'>
          {t('tkReportEntitlements')}
        </div>
        <div className='reports-container'>
          <div className='reports-left'>
            <FeaturesGroup data={coreReports} title='tkCoreReports' selectedKey='coreReports' testId='core-report-subtitle' />
          </div>
          <div className='reports-right'>
            <FeaturesGroup data={otherReports} title='tkOtherReports' selectedKey='otherReports' testId='other-report-subtitle' />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Features;
