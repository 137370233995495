import { SWITCH } from '../../../constants';

export const featureConfig = {
  core: [
    {
      id: 1,
      label: 'tkContactUs',
      value: 'contactUs',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 2,
      label: 'tkFAQAndGlossary',
      value: 'faq',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 3,
      label: 'tkMessageCenter',
      value: 'messageCenter',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
  ],
  accounts: [
    {
      id: 4,
      label: 'tkAccountDetails',
      value: 'accountDetails',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 5,
      label: 'tkActivityTransactions',
      value: 'currentTradeStatus',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 6,
      label: 'tkInvestmentPolicy',
      value: 'investmentPolicy',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 7,
      label: 'tkYourPortfolio',
      value: 'portfolio',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 8,
      label: 'tkReporting',
      value: 'reporting',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
  ],
  funds: [
    {
      id: 9,
      label: 'tkFundFinder',
      value: 'fundfinder',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 10,
      label: 'tkFundTracker',
      value: 'fundtracker',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 11,
      label: 'tkGSAMFloatingNAVCalc',
      value: 'navCal',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 12,
      label: 'tkNavTransparencyInsights',
      value: 'transpInsight',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
  ],
  trading: [
    {
      id: 13,
      label: 'tkTradeBlotter',
      value: 'trading',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 14,
      label: 'tkTradeImport',
      value: 'tradeImport',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 15,
      label: 'tkTradeApproval',
      value: 'trdApproval',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
  ],
  coreReports: [
    {
      id: 16,
      label: 'tkAccountBalances',
      value: 'reportingAccountBalance',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 17,
      label: 'tkCurrentTradeStatus',
      value: 'reportingCurrTradeStatus',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 18,
      label: 'tkCurrentTradeTotalsbyFund',
      value: 'reportingCurrTradeTotalByFund',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 19,
      label: 'tkFundBalances',
      value: 'reportingFundBalance',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 20,
      label: 'tkFundRatesandFactors',
      value: 'fundRatesAndFactors',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 21,
      label: 'tkGainLoss',
      value: 'reportingGainLossOnshore',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 22,
      label: 'tkHistoricalTransaction',
      value: 'reportingHistoricalTxn',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 23,
      label: 'tkMonthlyDividendActivity',
      value: 'reportingMonthlyDividenedActivity',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 24,
      label: 'tkWireSettlement',
      value: 'reportingWireSettlement',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
  ],
  otherReports: [
    {
      id: 25,
      label: 'tkClassFee',
      value: 'reportingClassFee',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 26,
      label: 'tkDebitCreditAccountListing',
      value: 'reportingDebitCreditAccListing',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 27,
      label: 'tkDebitCreditDailyFeedSummaryActivity',
      value: 'reportingDebitCreditDailyFeedSummary',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 28,
      label: 'tkDebitCreditDailyTransactionActivity',
      value: 'reportingDebitCreditTxnActivity',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 29,
      label: 'tkDebitCreditSettlementSummary',
      value: 'reportingDebitCreditSettlementSummary',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 30,
      label: 'tkExPostCostsChargesSummary',
      value: 'reportingExPostCostAndChargesSummary',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 31,
      label: 'tkHistoricalTradeApprovalException',
      value: 'reportingHistoricalTradeApprovalException',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 32,
      label: 'tkMonthlyServiceFee',
      value: 'reportingMonthlyServiceFee',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    /*
    {
      id: 33,
      label: 'tkTradeWebActivity',
      value: 'tradeweb',
    },
    */
  ],
};
