import React, { memo, useState } from 'react';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { Button } from '@gs-ux-uitoolkit-react/button';
import InputField from './InputField';
import SwitchField from './SwitchField';
import DropdownField from './DropdownField';
import InputWithSwitch from './InputWithSwitch';
import AutoCompleteOrgSearch from './AutoCompleteOrgSearch';
import AutoCompleteUserDetail from "./AutoCompleteUserDetail"
import ChangeManagementModal from '../ChangeManagementDetailsModal/Modal';
import translator from '../../../../../services/translator';
import { SWITCH, DROPDOWN, NA, TEXT, TEXT_WITH_SWITCH, AUTOCOMPLETE_ORG_SEARCH, AUTOCOMPLETE_USER_DETAILS } from '../../constants';
import './index.scss';

const { translate: t } = translator;
const FieldRenderer = props => {
  const {
    config,
    value,
    isEditable,
    editModeDropdownData = [],
    featuresIcon,
    sectionStart,
    sectionEnd,
    showNAIfNull,
    handleChange,
    errorState,
    canDeleteField,
    activeIcon,
    fieldIndex,
    pageKey
  } = props;
  const { label: title, editableFieldType, valueFormatter, dropdownKey, index = '', viewCta } = config;

  const [displayChangeManagementModal, setDisplayChangeManagementModal] = useState(false);

  const getFormattedValue = () => {
    let val = value;
    if (valueFormatter) {
      val = valueFormatter(val, isEditable);
    }
    if (val === 'null' && showNAIfNull) {
      return NA;
    } else if (val && typeof val !== 'boolean') {
      return val;
    } else {
      return showNAIfNull ? NA : t('tkNullDataPlaceholder');
    }
  };

  const fieldValue = getFormattedValue();

  const getDropdownLabel = () => {
    const { label } = (editModeDropdownData && editModeDropdownData[dropdownKey]?.find(({ value }) => value === fieldValue)) || {};
    return label;
  };

  const renderStatusValue = () => {
    return (
      <>
        <span
          data-testid='status-icon'
          className={cn({
            userdetails__activeIcon: fieldValue === 'Active',
            userdetails__inactiveIcon: fieldValue === 'Inactive',
          })}
        />
        {fieldValue}
      </>
    );
  };

  const renderEditComponents = () => {
    switch (editableFieldType) {
      case TEXT:
        return <InputField config={config} value={value} errorState={errorState} handleChange={handleChange} />;
      case SWITCH:
        return <SwitchField config={config} value={value} handleChange={handleChange} />;
      case DROPDOWN:
        return <DropdownField
          errorState={errorState} config={config} value={value} handleChange={handleChange} options={editModeDropdownData[config.dropdownKey]} />;
      case TEXT_WITH_SWITCH:
        return <InputWithSwitch config={config} value={value} errorState={errorState} handleChange={handleChange} />;
      case AUTOCOMPLETE_ORG_SEARCH:
        return (
          <AutoCompleteOrgSearch
            fieldIndex={fieldIndex}
            config={config}
            value={value}
            handleChange={handleChange}
            errorState={errorState}
            canDeleteField={canDeleteField}
          />
        );
      case AUTOCOMPLETE_USER_DETAILS:
        return (
          <AutoCompleteUserDetail
            fieldIndex={fieldIndex}
            config={config}
            value={value}
            handleChange={handleChange}
            errorState={errorState}
          />
        );
      default:
        return fieldValue;
    }
  };

  const openChangeManagementModal = () => {
    setDisplayChangeManagementModal(true);
  };

  const closeChangeManagementModal = () => {
    setDisplayChangeManagementModal(false);
  };

  const renderViewButton = () => {
    return (
      <Button actionType='primary' onClick={openChangeManagementModal} emphasis='minimal' className='userdetails__viewButton'>
        View
      </Button>
    );
  };

  const renderEditableValues = () => {
    if (activeIcon) {
      return renderStatusValue();
    } else {
      return renderEditComponents();
    }
  };

  const renderValue = () => {
    if (isEditable) {
      return renderEditableValues();
    } else if (featuresIcon) {
      return <span data-testid='feature-icon' className='userdetails__featuresIcon' />;
    } else if (editableFieldType === DROPDOWN) {
      return getDropdownLabel();
    } else if (activeIcon) {
      return renderStatusValue();
    }
    return typeof fieldValue === "string" ? fieldValue : '';
  };

  return (
    <div
      data-testid='userDetails-container'
      className={cn('userdetails', {
        userdetails__sectionStart: sectionStart,
        userdetails__sectionEnd: sectionEnd,
      })}>
      <div className='userdetails__label'>
        <div className='userdetails__field'>{t(title, index)}</div>
      </div>
      <div className='userdetails__detail'>
        <div
          className={cn('userdetails__value', {
            userdetails__switch: isEditable && editableFieldType === SWITCH,
            userdetails__dropdown: isEditable && editableFieldType === DROPDOWN,
            userdetails__autoComplete: isEditable && editableFieldType === AUTOCOMPLETE_ORG_SEARCH,
          })}>
          {renderValue()}
        </div>
      </div>
      {viewCta && viewCta(pageKey) && renderViewButton()}
      {displayChangeManagementModal && <ChangeManagementModal closeModal={closeChangeManagementModal} />}
    </div>
  );
};

FieldRenderer.propTypes = {
  handleChange: PropTypes.func,
  config: PropTypes.object,
  sectionStart: PropTypes.bool,
  sectionEnd: PropTypes.bool,
  value: PropTypes.any,
  isEditable: PropTypes.bool,
  featuresIcon: PropTypes.bool,
  editModeDropdownData: PropTypes.object,
  showNAIfNull: PropTypes.bool,
  errorState: PropTypes.object,
  canDeleteField: PropTypes.bool,
  activeIcon: PropTypes.bool,
  fieldIndex: PropTypes.number,
  pageKey: PropTypes.string
};

const propsAreEqual = (prevProps, nextProps) => {
  const { handleChange, config, editModeDropdownData, ...restPrevProps } = prevProps;
  const { handleChange: change, config: nextConfig, editModeDropdownData: dropdownData, ...restNextProps } = nextProps;
  return isEqual(restPrevProps, restNextProps);
};

export default memo(FieldRenderer, propsAreEqual);
