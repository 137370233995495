import React from 'react';
import { Select } from '@gs-ux-uitoolkit-react/select';
import PropTypes from 'prop-types';
import translator from '../../../../../services/translator';

const { translate: t } = translator;

const DropdownField = props => {
  const { value, config, handleChange, options, errorState: { errorMessage } = {} } = props;

  const handleSelectChange = newValue => {
    handleChange({
      value: newValue.selectedValue,
      config,
      errorMessage: false
    });
  };

  return (
    <div>
      <Select
        status={errorMessage ? 'error' : 'none'}
        className={errorMessage ? 'profile-dropdown input-edit__error' : 'profile-dropdown input-edit__default'}
        options={options}
        onSelect={handleSelectChange}
        placeholder={t('tkPleaseSelect')}
        selectedValue={value}
        clearable={false}
      />
      {errorMessage && (
        <span className='error-message' data-testid='text-errorMessage'>
          {errorMessage}
        </span>
      )}
    </div>
  )
};

DropdownField.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.array,
  config: PropTypes.object,
  errorState: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default DropdownField;
