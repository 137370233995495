import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PageHeader from '../../../../../components/app/PageHeader';
import { updateCurrentPage } from '../../actions';
import Snackbar from '../../../../../components/core/Snackbar/index';
import SnackbarList from './SnackbarList';
import { labelSelector } from '../../../../../selectors/app';
import { autocompleteSnackbarList } from '../../selectors';
import Constants from '../../../../../constants/appConstants';
import { addPageContext } from '../../../../../actions/page';
import { PAGES } from '../../reducers';
import { CREATE_USER } from '../../constants';
import './index.scss';

const UserSearch = ({ snackbarItems = [], labels, onNewUserCreation }) => (
  <div className='portfolio__header'>
    <SnackbarList />
    <TransitionGroup component={null}>
      {snackbarItems.map((data, idx) => (
        <CSSTransition key={idx} classNames='snackbar__transition' timeout={Constants.CSS_DURATION_HORSE}>
          <Snackbar labels={labels} {...data} />
        </CSSTransition>
      ))}
    </TransitionGroup>
    <div className='header-container'>
      <div className='header-container__left'>
        {PageHeader({
          title: labels.tkAdministratorDashboard,
          description: labels.tkAdministratorDashboardDescription || '--',
        })}
      </div>
      <Button actionType='primary' onClick={onNewUserCreation}>
        <Icon name='person-add' type='filled' />
        <span className='gs-btn'>{labels.tkSetupNewUser}</span>
      </Button>
    </div>
  </div>
);

UserSearch.propTypes = {
  labels: PropTypes.object,
  snackbarItems: PropTypes.array,
  autocomplete: PropTypes.array,
  onNewUserCreation: PropTypes.func,
};

const mapStateToProps = state => ({
  labels: labelSelector(state),
  snackbarItems: autocompleteSnackbarList(state),
});

const mapDispatchToProps = dispatch => ({
  onNewUserCreation: () => {
    dispatch(updateCurrentPage(PAGES.DETAILS_PAGE));
    dispatch(addPageContext({ isUser: true, isOrganization: false, pageKey: CREATE_USER }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);
