import React, { useState, useMemo } from 'react';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { cloneDeep } from 'lodash';
import UserDetailsModal from '../../UserDetails/Modal';
import PageHeader from '../../../../../../components/app/PageHeader';
import { Conditional } from '../../../../../../components/core/Conditional';
import useUserMaintenance from '../../../context';
import useSaveOrgDetails from '../../../hooks/useSaveOrgDetails';
import useSnackBar from '../../../hooks/useSnackbar';
import { ORG_EDIT_MODE_ACTIVE, OPEN_MODAL, SAVE_BTN_CLICKED, updateCurrentPage, DISCARD_ORG_CHANGES } from '../../../actions';
import { PAGES } from '../../../reducers';
import { MODAL, ACTIVE } from '../../../constants';
import { addPageContext } from '../../../../../../actions/page';
import translator from '../../../../../../services/translator';
import { getInitialGridData } from '../Funds/OrgDetailsGrid';

const { translate: t } = translator;

const Header = () => {
  const {
    state: {
      organizationDetails,
      isOrgEditable,
      modal: [unsavedChangesModal],
      orgDetailsValidation,
      backUpOrganizationDetails: { relationships: backupRelationships = [] } = {},
      mapUnmapFirmsList,
      updatedShareclassesAndFundGroups,
    },
    dispatch,
    reduxDispatch,
  } = useUserMaintenance();

  const { organizationName, organizationId, status, relationships = [] } = organizationDetails || {};

  const { onSaveOrgDetails } = useSaveOrgDetails();

  const { openSnackbar } = useSnackBar();

  const [isBackBtnClicked, setIsBackBtnClicked] = useState(false);

  const existingScFgIds = useMemo(() => Object.keys(getInitialGridData(organizationDetails.funds)), [organizationDetails.funds]);

  const isPageEdited = useMemo(() => {
    if (isOrgEditable) {
      if (relationships.length !== backupRelationships.length || mapUnmapFirmsList.length) {
        return true;
      }
      const cpyRelationships = cloneDeep(relationships);
      const cpyBckRelationships = cloneDeep(backupRelationships);
      cpyRelationships.forEach(relation => {
        const matchedIdx = cpyBckRelationships.findIndex(
          item => relation.organizationId === item.organizationId && relation.relationshipType === item.relationshipType
        );
        if (matchedIdx !== -1) {
          cpyBckRelationships.splice(matchedIdx, 1);
        }
      });
      if (cpyBckRelationships.length) {
        return true;
      }
      if (updatedShareclassesAndFundGroups) {
        return Object.values(updatedShareclassesAndFundGroups).some(
          item => (!existingScFgIds.includes(item) || (existingScFgIds.includes(item) && item.operation === 'unmap')) && item.modifiedCount % 2 !== 0
        );
      }
    }
    return false;
  }, [relationships, backupRelationships, mapUnmapFirmsList, updatedShareclassesAndFundGroups, existingScFgIds]);

  const onBackClickWithoutSave = () => {
    setIsBackBtnClicked(true);
    dispatch({ type: OPEN_MODAL, payload: { modalName: MODAL.unsavedChanges } });
  };

  const saveChanges = () => {
    dispatch({ type: SAVE_BTN_CLICKED, payload: { saveBtn: true } });
    if (Object.keys(orgDetailsValidation).length) {
      openSnackbar({ type: 'error', message: t('tkInvalidInputSnackbarMessage') });
    } else {
      const fundsEditPayload = Object.values(updatedShareclassesAndFundGroups).reduce(
        (acc, cur) => {
          const { id, modifiedCount, operation, isShareclass } = cur;
          if (modifiedCount % 2 !== 0) {
            if (operation === 'map' && isShareclass) {
              acc.shareclasses.map.push(id);
            } else if (cur.operation === 'unmap' && cur.isShareclass) {
              acc.shareclasses.unmap.push(cur.id);
            } else if (cur.operation === 'map' && !cur.isShareclass) {
              acc.fundGroups.map.push(cur.id);
            } else if (cur.operation === 'unmap' && !cur.isShareclass) {
              acc.fundGroups.unmap.push(cur.id);
            }
          }
          return acc;
        },
        {
          // fundGroups: { map: [], unmap: [] }, shareclasses: { map: [], unmap: [] }
        }
      );
      onSaveOrgDetails({
        relationships: relationships
          .filter(relationship => relationship.organizationId)
          .map(({ organizationId, relationshipType }) => ({
            organizationId,
            relationshipType,
            organizationRelationType: 'SOURCE',
          })),
        firms: [...mapUnmapFirmsList].map(item => {
          const { firmId, ...rest } = item;
          return { ...rest, id: firmId };
        }),
        ...fundsEditPayload,
        fundGroups: {},
        shareclasses: {},
      });
    }
  };

  const onCancelClick = () => {
    if (isPageEdited) {
      dispatch({ type: OPEN_MODAL, payload: { modalName: MODAL.unsavedChanges } });
    } else {
      if (isBackBtnClicked) {
        setIsBackBtnClicked(false);
      }
      dispatch({ type: DISCARD_ORG_CHANGES });
    }
  };

  const updateBackClickState = () => {
    setIsBackBtnClicked(false);
  };

  const onEdit = () => {
    dispatch({ type: ORG_EDIT_MODE_ACTIVE, payload: { isOrgEditable: true } });
  };

  const onBack = () => {
    reduxDispatch(updateCurrentPage(PAGES.LANDING_PAGE));
    reduxDispatch(addPageContext({ pageKey: null }));
  };

  return (
    <div className='portfolio__header user-profile' data-testid='org-details-header'>
      <Conditional condition={unsavedChangesModal.display}>
        <UserDetailsModal
          modalName='unsavedChanges'
          onBack={onBack}
          type='ORG'
          isBackBtnClicked={isBackBtnClicked}
          updateBackClickState={updateBackClickState}
        />
      </Conditional>
      <div className='control-panel'>
        <div className='control-panel__left'>
          <Button className='back-btn' actionType='primary' emphasis='minimal' onClick={isPageEdited ? onBackClickWithoutSave : onBack}>
            <Icon name='keyboard-arrow-left' type='filled' />
            {t('tkBacktoAdminDashboard')}
          </Button>
        </div>
        <div className='control-panel__right' data-testid='internal-edit-actions'>
          {isOrgEditable ? (
            <>
              <Button actionType='primary' disabled={!isPageEdited} onClick={saveChanges} className='control-panel__right--Btns'>
                {t('tkSave')}
              </Button>
              <Button actionType='primary' emphasis='subtle' onClick={onCancelClick} className='control-panel__right--Btns'>
                {t('tkCancel')}
              </Button>
            </>
          ) : (
            status === ACTIVE && (
              <Button actionType='primary' onClick={onEdit} className='control-panel__right--Btns'>
                <Icon name='edit' type='filled' size='sm' />
                {t('tkEdit')}
              </Button>
            )
          )}
        </div>
      </div>
      <div className='user-profile-header'>
        {PageHeader({
          title: `${organizationName}`,
          description: `${organizationId}`,
        })}
      </div>
    </div>
  );
};

export default Header;
